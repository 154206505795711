.searchBar {
    flex-grow: 1;
    margin: 0.5625rem 0;
    padding: 0 1rem;

    input {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 15px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
    }

    .searchBar-icon {
        position: absolute;
        top: 50%;
        height: auto;
        width: 30px;
        text-align: center;
        color: #d0d0d0;
    }
}