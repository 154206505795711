.OurFactory {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;
    color: #666;

    .title-block {
        width: 100%;
        padding: 3rem 0;
        text-align: center;

        h1 {
            font-size: 2.5rem;
        }
    }

    .introduce-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 100%;
        align-self: stretch;
        box-sizing: border-box;
        padding: 3rem 1rem;
        background-color: #509c44;

        p {
            color: #fff;
            font-size: 1.3rem;
            letter-spacing: 0px;
            line-height: 2.1rem;
        }

        &.chinese-content {
            p {
                letter-spacing: 3px;
            }
        }

        &.english-content {
            p {
                letter-spacing: 0.5px;
            }
        }
    }

    .factory-history {
        flex-basis: 100%;
        box-sizing: border-box;
        padding: 1rem;

        .process {
            width: 100%;

            table {
                margin: 0 auto;                
                color: #666;

                td {
                    &.year {
                        padding: 0.3rem 0;

                        >div {
                            width: 3.5rem;
                            height: 3.5rem;
                            box-sizing: border-box;
                            padding: 13px 0;
                            border-radius: 50%;
                            text-align: center;
                            font-size: 20px;
                            color: #fff;
                            background-color: #509c44;
                        }
                    }

                    &.dot {
                        padding: 0 0.5rem;
                        font-size: 40px;
                    }

                    &.content {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .factory-introduce {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .factory-picture {
            display: flex;
            box-sizing: border-box;
            flex-basis: 100%;

            picture {
                display: flex;
                
                img {
                    width: 100%;
                }
            }

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(4) {
                order: 3;
            }
        }

        .factory-content {
            box-sizing: border-box;
            padding: 1rem;
            flex-basis: 100%;

            h3 {
                font-size: 1.5rem;
            }

            p {
                font-size: 20px;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .OurFactory {
        .introduce-block {
            flex-basis: 50%;
            padding: 3rem 5rem;
        }

        .factory-history {
            flex-basis: 50%;
            //border-top: 1px solid #509c44;
            //border-left: 1px solid #509c44;
        }

        .factory-introduce {
            .factory-picture {
                flex-basis: 50%;

                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(4) {
                    order: 4;
                }
            }

            .factory-content {
                flex-basis: 50%;

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }
            }
        }
    }
}