.SearchResult {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 3rem;

    .title {
        text-align: center;
    }

    .react-tabs {
        .product-tab-panel {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            flex-grow: 1;

            .product {
                padding: 1rem;
                flex-basis: 100%;
                box-sizing: border-box;
                cursor: pointer;

                .image-container {
                    >img {
                        width: 100%;
                        min-width: 148px;
                    }
                }

                >p {
                    margin-top: 0.5rem;
                    text-align: center;
                    font-size: 18px;
                    color: #293a4e;
                }
            }
        }

        .news-tab-panel {
            display: flex;
            flex-flow: row wrap;

            .news-card {
                display: flex;
                flex-flow: row nowrap;
                flex-basis: 100%;
                box-sizing: border-box;
                padding: 1rem 0.5rem;
        
                .news-image-container {
                    flex-basis: 50%;
                    overflow: hidden;
        
                    &::after {
                        content: '';
                        width: 100%;
                        padding-top: calc(1 * 100% / 1);
                        display: block;
                    }
        
                    .image-holder {
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        height: 100%;
                    }
                }
        
                .news-content-container {
                    flex-basis: 50%;
                    box-sizing: border-box;
                    padding: 0 1rem;
        
                    .news-category {
                        display: inline-block;
                        margin-right: 0.8rem;
                        padding: 0.2rem 0.8rem;
                        border-radius: 5px;
                        color: #fff;
                        background-color: #767676;
                    }
        
                    .news-release-date {
                        display: inline-block;
                    }
        
                    h2 {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: auto;
                    }
        
                    b {
                        display: block;
                        height: 1px;
                        background-color: #767676;
                        margin: 1rem 0;
                    }
        
                    a {
                        display: inline-block;
                        padding: 0.5rem 1.2rem;
                        color: #fff;
                        background-color: #509c44;
                        text-decoration: none;
                        cursor: pointer;
        
                        &:hover {
                            background-color: #65c556;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 360px) {
    .SearchResult {
        .react-tabs {
            .product-tab-panel {
                .product {
                    flex-basis: 50%;
                }
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .SearchResult {
        .react-tabs {
            .product-tab-panel {
                .product {
                    flex-basis: 33%;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .SearchResult {
        .react-tabs {
            .product-tab-panel {
                .product {
                    flex-basis: 25%;
                }
            }

            .news-tab-panel {
                .news-card {
                    flex-basis: 50%;
                    padding: 1rem 0;
                }
            }
        }
    }
}