.ContactUs {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    .contact-us-form {
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 90%;
        padding: 1rem;
        box-sizing: border-box;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        font-size: 18px;
        background-color: #509c44;

        .form-row {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            flex-basis: 100%;
            padding: 0.3rem 0;
            max-width: 100%;

            label {
                flex-basis: 100%;

                &.mark {
                    font-weight: bold;

                    &::before {
                        content: '＊'
                    }
                }

                &::before {
                    content: '　'
                }
            }

            input {
                flex-basis: 100%;
            }

            select {
                flex-basis: 100%;
                max-width: 100%;
                font-size: 14px;
            }

            textarea {
                flex-basis: 100%;
                max-width: 768px;
                height: 156px;
            }

            button {
                font-size: 1rem;
                color: #ffffff;
                background-color: #555555;
                width: 5rem;
                padding: 5px 0;
                border: 0;
                border-radius: 5px;

                &:hover {
                    background-color: #666;
                }

                &:active {
                    background-color: #555;
                }
            }

            &.button-block {
                justify-content: center;
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .ContactUs {
        .contact-us-form {
            width: 700px;
            padding: 1rem 5rem;

            .form-row {
                label {
                    flex-basis: 8rem;
                }

                input {
                    flex-basis: 280px;
                }

                select {
                    flex-basis: auto;
                    max-width: 288px;
                }

                textarea {
                    flex-basis: 400px;
                }
            }
        }
    }
}