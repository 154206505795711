.MessageModal {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;

    .modal {
        background-color: #fff;
        flex-basis: 70%;
        box-sizing: border-box;
        padding: 1rem 1rem 1rem 2rem;
        border-radius: 10px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);

        .toolbar {
            text-align: right;

            svg {
                width: 22px;
                height: 22px;
                box-sizing: border-box;
                padding: 2px;
                border-radius: 50%;
                font-size: 18px;
                color: #888;
                cursor: pointer;

                &:hover {
                    border: 1px solid #ddd;
                    background-color: #eee;
                }
            }
        }

        .title {
            text-align: left;

            h3 {
                margin-top: 0;

                &.error {
                    svg {
                        color: #ff2f00;
                    }
                }

                &.alert{
                    svg {
                        color: #ff8d00;
                    }
                }
                
                &.success {
                    svg {
                        color: #1dbf1d;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .MessageModal {
        .modal {
            flex-basis: 350px;
        }
    }
}