.Customize {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    form {
        display: flex;
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        border-radius: 10px;
        background-color: #509c44;

        .form-row {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            flex-basis: 100%;
            padding: 0.5rem 0;

            &.submit {
                justify-content: center;
                margin-top: 1.5rem;
            }

            label {
                flex-basis: 100%;

                &.mark {
                    font-weight: bold;

                    &::before {
                        content: '＊';
                    }
                }

                &::before {
                    content: '　';
                }
            }

            >input {
                flex-basis: 100%;
            }

            input {
                margin-left: 1rem;
            }

            .input-group {
                flex-basis: 100%;

                &.flex {
                    display: flex;
                    flex-flow: row wrap;

                    .input-item {
                        flex-basis: 100%;
                    }
                }
            }

            textarea {
                width: 100%;
                height: 150px;
                margin-left: 1rem;
            }

            button {
                padding: 0.3rem 1rem;
                border: 0;
                border-radius: 5px;
                color: #fff;
                font-size: 16px;
                background-color: #555;
                cursor: pointer;

                &:hover {
                    background-color: #666;
                }

                &:active {
                    background-color: #555;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .Customize {
        form {
            width: 700px;
            padding: 1.5rem 4rem;

            .form-row {
                label {
                    flex-basis: 12rem;
                }

                >input {
                    flex-basis: 280px;
                }

                input {
                    margin-left: 0;

                    &.input-other {
                        margin-left: 0.5rem;
                    }
                }

                .input-group {
                    flex-basis: 295px;
                }

                textarea {
                    width: 374px;
                    margin-left: 0;
                }
            }
        }
    }
}