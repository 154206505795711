.languageSelector {
    display: flex;
    position: relative;
    padding: 0.5rem 1.2rem;
    border: 1px solid #dfdfdf;
    border-radius: 25px;
    color: #555555;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: #fbfbfb;
    }

    .languageSelector-country-display {
        >.language-name {
            display: inline-block;
            width: auto;
            padding: 0 0.5rem 0 0.3rem;
        }
    }
}