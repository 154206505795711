ol.header-menu-bar {
    li {
        >ol {
            display: none;
            list-style: none;
            padding: 1rem 0;
            color: #555555;
            background-color: #ffffff;

            &.expanded {
                display: block;
                position: absolute;
                margin-top: 0.5rem;
                border-radius: 5px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
            }

            >li {
                position: relative;
                padding: 0.5rem 2rem;

                &:hover {
                    background-color: #fbfbfb;
                    color: #000000;
                }
            }
        }
    }
}