.Footer {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #444444;
    color: #cccccc;

    .footer-logo {
        padding: 2rem 1rem;

        img {
            width: 5rem;
        }
    }

    .company-info {
        padding: 1.5rem 0;

        .info-title {
            display: inline-block;
        }

        .info-value {
            display: inline-block;
        }
    }
}

@media screen and (min-width: 768px) {
    .Footer {
        .footer-logo {    
            img {
                width: 8rem;
            }
        }
    }
}