@import 'https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap';

.Introduce {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    padding-bottom: 3rem;
    color: #555555;

    .content-block {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        flex-basis: 100%;
        padding: 2rem 0;
        color: #fff;
        background-color: #509c44;

        h1 {
            flex-basis: 100%;
            text-align: center;
            font-size: 2.3rem;
            letter-spacing: 5px;

            &#company-title {
                font-family: 'Playfair Display', sans-serif;
            }
        }

        &:nth-child(1) {
            order: 1;
        }

        &:nth-child(8) {
            order: 8;
        }
    }

    .block-title {
        flex-basis: 100%;
        text-align: center;
        font-size: 1.8rem;

        &:nth-child(9) {
            order: 9;
        }
    }

    .company-picture {
        display: flex;
        box-sizing: border-box;
        flex-basis: 100%;

        picture {
            display: flex;
            
            img {
                width: 100%;
            }
        }

        &:nth-child(2) {
            order: 2;
        }

        &:nth-child(5) {
            order: 4;
        }

        &:nth-child(6) {
            order: 6;
        }
    }

    .introduce-content {
        box-sizing: border-box;
        flex-basis: 100%;
        padding: 1rem 2rem;
        font-size: 20px;

        h2 {
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
        }

        a {
            display: inline-block;
            margin: 1.2rem 0 0 0;
            padding: 0.5rem 1.2rem;
            border: 0;
            font-size: 1rem;
            color: #fff;
            background-color: #509c44;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                background-color: #65c556;
            }
        }

        &:nth-child(3) {
            order: 3;
        }

        &:nth-child(4) {
            order: 5;
        }

        &:nth-child(7) {
            order: 7;
        }
    }

    .sub-title {
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box;
        align-content: space-around;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;

        >b {
            height: 2px;
            background-color: #cdcdcd;
            flex-grow: 1;
        }

        h2 {
            margin-left: 1rem;
            margin-right: 1rem;
            text-align: center;
        }
    }

    .news-container {
        display: flex;
        flex-flow: column nowrap;
        box-sizing: border-box;
        padding: 1rem;
        flex-basis: 100%;

        .image-container {
            overflow: hidden;

            &::after {
                content: '';
                width: 100%;
                padding-top: 100%;
                display: block;
            }

            .image-holder {
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                height: 100%;
                cursor: pointer;
            }
        }

        .news-content {
            cursor: pointer;

            h2 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            .image-container {
                -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.3);
                box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.3);
                transform: scale(1.05) translateY(-8px);
            }

            .news-content {
                h2 {
                    text-shadow: rgba(0, 0, 0, 0.3) 0px 3px 3px;
                    transform: scale(1.05) translateY(-8px);
                }
            }
        }
    }

    .sales-content {
        display: flex;
        flex-flow: column nowrap;
        box-sizing: border-box;
        flex-basis: 100%;
        padding: 1rem 3rem;

        .sales-picture {
            -webkit-mask: url('../../../public/images/circle.png') no-repeat center center / contain;
            mask: url('../../../public/images/circle.png') no-repeat center center / contain;
            height: 200px;

            &#sales_lin {
                background: url('../../../public/images/index/introduce/sales_lin.jpg') no-repeat center center / contain;
            }

            &#sales_ron {
                background: url('../../../public/images/index/introduce/sales_ron.jpg') no-repeat center center / contain;
            }

            &#sales_cao {
                background: url('../../../public/images/index/introduce/sales_cao.jpg') no-repeat center center / contain;
            }
        }

        .sales-name {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
        }

        .sales-contact {
            text-align: center;

            svg {
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                color: #555;
                margin: 0 0.3rem;
                padding: 5px;
                border: 1px solid #555;
                border-radius: 50%;

                &:hover {
                    background-color: #65c556;
                }
            }
        }

        &:nth-child(10) {
            order: 10;
        }

        &:nth-child(11) {
            order: 11;
        }

        &:nth-child(12) {
            order: 12;
        }
    }
}

@media screen and (min-width: 768px) {
    .Introduce {
        .content-block {
            h1 {
                font-size: 4rem;
            }

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(8) {
                order: 8;
            }
        }

        .block-title {
            &:nth-child(9) {
                order: 9;
            }
        }

        .company-picture {
            flex-basis: 50%;

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(5) {
                order: 5;
            }

            &:nth-child(6) {
                order: 6;
            }
        }

        .introduce-content {
            flex-basis: 50%;

            &:nth-child(3) {
                order: 3;
            }

            &:nth-child(4) {
                order: 4;
            }

            &:nth-child(7) {
                order: 7;
            }
        }

        .sales-content {
            flex-basis: 30%;

            &:nth-child(10) {
                order: 10;
            }

            &:nth-child(11) {
                order: 11;
            }

            &:nth-child(12) {
                order: 12;
            }
        }

        .news-container {
            flex-basis: 30%;
        }
    }
}