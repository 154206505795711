.QualityControl {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;

    .qc-title {
        width: 100%;
        height: 150px;
        text-align: center;
        background-color: #509c44;
        color: #fff;

        h1 {
            position: relative;
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
        }
    }

    .qc-content-container {
        display: flex;
        flex-flow: row wrap;

        .content-block {
            flex-basis: 100%;
            padding: 1rem 1rem;
            box-sizing: border-box;

            picture {
                display: flex;
                
                img {
                    width: 100%;
                }
            }

            p {
                color: #666;
                text-align: center;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .QualityControl {
        .qc-content-container {
            .content-block {
                picture {
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}