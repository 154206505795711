.Products {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;

    .products-items {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        flex-grow: 1;

        .product-category-selector {
            display: none;
        }

        .product-filter-btn {
            flex-basis: 100%;
            text-align: center;

            >button {
                padding: 0.2rem 1rem;
                border: 1px solid #dfdfdf;
                border-radius: 10px;
                font-size: 16px;
                color: #555555;
                background-color: #fbfbfb;
            }

            >.total-result {
                padding-top: 1rem;
                color: #555;
            }
        }

        .product {
            padding: 1rem;
            flex-basis: 100%;
            box-sizing: border-box;
            cursor: pointer;

            .image-container {
                >img {
                    width: 100%;
                    min-width: 148px;
                }
            }

            >p {
                margin-top: 0.5rem;
                text-align: center;
                font-size: 18px;
                color: #293a4e;
            }
        }
    }

    .category-selector {
        position: absolute;
        height: calc(100vh - 89px);
        width: 100vw;
        top: 89px;
    }
}

@media screen and (min-width: 360px) {
    .Products {
        .products-items {
            .product {
                flex-basis: 50%;
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .Products {
        .products-items {
            .product {
                flex-basis: 33%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .Products {
        .products-items {
            .product-category-selector {
                display: flex;
                flex-basis: 100%;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                justify-content: flex-end;

                >.css-b62m3t-container {
                    width: 180px;
                    box-sizing: content-box;
                    padding: 0 1rem;
                }

                >.total-result {
                    box-sizing: border-box;
                    padding: 0 1rem;
                    color: #555;
                }
            }

            .product-filter-btn {
                display: none;
            }

            .product {
                flex-basis: 25%;
            }
        }
    }
}