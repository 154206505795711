.SideMenu {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 74px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;

    a {
        color: #555555;
        text-decoration: none;
    }

    .menu-container {
        position: fixed;
        min-width: 40%;
        height: calc(100% - 74px);
        padding-right: 1.5rem;
        padding-bottom: 2rem;
        background-color: #ffffff;
        overflow: auto;

        ol {
            list-style-type: none;
            padding-left: 1.5rem;

            li {
                padding: 0.3rem 0;
            }
        }

        .language-selector {
            padding-left: 1rem;
        }
    }
}

@media screen and (min-width: 823px) {
    .SideMenu {
        display: none;
    }
}