@font-face {
  font-family: 'Get-Social-Font';
  font-weight: normal;
  font-style: normal;
  src: local('Get-Social-Font'), url(./fonts/Get-Social-Font.eot) format('embedded-opentype'),
    local('Get-Social-Font'), url(./fonts/Get-Social-Font.ttf) format('truetype'),
    local('Get-Social-Font'), url(./fonts/Get-Social-Font.woff) format('woff'),
    local('Get-Social-Font'), url(./fonts/Get-Social-Font.svg) format('svg')  
}

.gs-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Get-Social-Font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-facebook:before{
    content: '\e604';
  }
  &.icon-youtube:before{
    content: '\e62f';
  }
}

body {
  margin: 0;
  padding-top: 88px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #444444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-next {
  color: #509c44;
  margin-right: 10px;

  &::after {
    content: '＞';
    border-radius: 50%;
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 40px;
    width: 45px;
    height: 40px;
    box-shadow: 2px 1px 5px 2px rgb(0, 0, 0, 0.3);
  }
}

.swiper-button-prev {
  color: #509c44;
  margin-left: 10px;

  &::after {
    content: '＜';
    border-radius: 50%;
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 40px;
    width: 45px;
    height: 40px;
    box-shadow: 2px 1px 5px 2px rgb(0, 0, 0, 0.3);
  }
}

.swiper-pagination-bullet {
  background-color: #509c44 !important;
  opacity: 0.4 !important;

  &.swiper-pagination-bullet-active {
    opacity: 1 !important;
  }
}