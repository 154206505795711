.header {
    .header-container {
        position: fixed;
        top: 0;
        width: 100%;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
        z-index: 9999;

        a {
            cursor: pointer;
            text-decoration: none;
            color: #555555;
        }

        .header-first-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            background-color: #509c44;

            .header-row-content {
                justify-content: flex-end;

                a {
                    padding: 0 0.5rem;
                    color: #FFF;
                    height: 21px;

                    &:visited {
                        color: #ffffff
                    }
                }
            }
        }

        .header-second-row {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            background-color: #ffffff;

            .header-row-content {
                justify-content: flex-start;

                .header-logo {
                    padding: 5px 1rem;
                    img {
                        height: 54px;
                    }
                }

                .header-menu-btn {
                    display: flex;
                    padding: 0.5rem;

                    >span {
                        position: relative;
                        width: 1.125rem;
                        height: 0.125rem;
                        background-color: #555555;

                        &::before {
                            position: absolute;
                            top: -6px;
                            content: '';
                            width: 1.125rem;
                            height: 0.125rem;
                            background-color: #555555;
                            transition: top, transform;
                            transition-duration: .1s;
                            transition-timing-function: ease-in-out;
                        }

                        &::after {
                            position: absolute;
                            top: 6px;
                            content: '';
                            width: 1.125rem;
                            height: 0.125rem;
                            background-color: #555555;
                            transition: top, transform;
                            transition-duration: .1s;
                            transition-timing-function: ease-in-out;
                        }
                    }

                    &.closed-type {
                        >span {
                            background-color: transparent;

                            &::before {
                                top: 0;
                                transform: rotate(-45deg);
                            }

                            &::after {
                                top: 0;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                ol.header-menu-bar {
                    display: none;
                }
            }

            .header-language-selector {
                display: none;
            }
        }

        .header-row-content {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 1080px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 823px) {
    .header {
        .header-container {
            .header-second-row {
                .header-row-content {
                    .header-menu-btn {
                        display: none
                    }

                    ol.header-menu-bar {
                        display: block;
                        margin: 0;
                        padding-left: 0;

                        >li {
                            display: inline-block;
                            padding: 0.5rem 0;

                            &:hover {
                                border-radius: 25px;
                                background-color: #fbfbfb;
                                color: #000000;
                            }

                            >a {
                                padding: 0 1rem;
                            }
                        }
                    }
                }

                .header-language-selector {
                    display: block;
                }
            }
        }
    }
}