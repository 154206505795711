li.sidememu-item {
    a {
        &.expanded {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    ol {
        display: none;

        &.expanded {
            display: block;
        }
    }
}