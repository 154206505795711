.NewsDetail {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 1rem;
    color: #555;

    .navigation-bar {
        a {
            color: #555;
        }
    }

    .news-title {
        flex-basis: 100%;

        h2 {
            margin: 1rem 0 0 0;
        }
    }

    .divider {
        display: block;
        flex-basis: 100%;
        height: 1px;
        background-color: #767676;
        margin: 0.5rem 0 3rem 0;
    }

    .image-container {
        flex-basis: 100%;
        overflow: hidden;

        &::after {
            content: '';
            width: 100%;
            padding-top: calc(3 * 100% / 5);
            display: block;
        }

        .image-holder {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
        }
    }

    .detail-content {
        flex-basis: 100%;
        box-sizing: border-box;
        padding: 1rem 0;

        pre {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            white-space: pre-wrap;
            word-wrap: break-word;
            font-size: 18px;
        }

        img {
            width: 100%;
            cursor: pointer;
        }
    }
    
    .button-bar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 3rem;
        flex-basis: 100%;

        a {
            padding: 0.5rem 0.8rem;
            color: white;
            background-color: #509c44;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                background-color: #65c556;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .NewsDetail {
        .image-container {
            flex-basis: 50%;
        }

        .detail-content {
            flex-basis: 50%;
            padding: 0 2rem;
        }
    }
}