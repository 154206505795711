.WhyUs {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;

    .image-block {
        position: relative;

        &::after {
            content: '';
            width: 100%;
            padding-top: calc(2 * 100% / 5);
            display: block;
        }

        .image-holder {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center 0;
            background-repeat: no-repeat;
        }
    }

    .title-block {
        width: 100%;
        padding: 3rem 0;
        text-align: center;
        color: #fff;
        background-color: #509c44;

        h1 {
            font-size: 2.5rem;
        }
    }

    .why-us-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: #666;

        .why-us-picture {
            display: flex;
            box-sizing: border-box;
            flex-basis: 100%;

            picture {
                display: flex;
                
                img {
                    width: 100%;
                }
            }

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(4) {
                order: 3;
            }
        }

        .why-us-description {
            box-sizing: border-box;
            padding: 1rem;
            flex-basis: 100%;

            h2 {
                font-size: 1.5rem;
            }

            p {
                font-size: 20px;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }
        }

        .product-overview {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0 1rem;
            color: #fff;
            background-color: #509c44;

            .product-card {
                position: relative;
                flex-basis: 100%;
                box-sizing: border-box;
                padding: 0.5rem 1rem 3rem 1rem;

                .cover {
                    position: absolute;
                    color: #fff;
                    font-size: 24px;
                    font-weight: bold;
                    background: rgba(135, 135, 135, 0.5);
                    width: calc(100% - 6rem);
                    bottom: 50%;
                    left: 3rem;
                    text-align: center;
                    text-shadow: 1px 1px 5px #000;
                }

                img {
                    width: 100%;
                }
            }

            &:nth-child(5) {
                order: 5;
            }
        }

        .technique-overview {
            display: flex;
            flex-flow: row wrap;

            .technique-introduction {
                flex-basis: 100%;
                box-sizing: border-box;
                padding: 0.5rem 1rem;

                img {
                    width: 100%;
                }

                p {
                    text-align: center;
                }
            }

            &:nth-child(7) {
                order: 7;
            }
        }

        .block-title {
            flex-basis: 100%;
            text-align: center;
            font-size: 1.8rem;

            &:nth-child(6) {
                order: 6;
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .WhyUs {
        .title-bar {
            img {
                margin-top: -110px;
            }
        }

        .why-us-content {
            .product-overview {
                &.sm {
                    display: none;
                }

                &.lg {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .WhyUs {
        .title-bar {
            height: 350px;

            >img {
                margin-top: 0px;
            }
        }

        .why-us-content {
            .why-us-picture {
                flex-basis: 50%;

                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(4) {
                    order: 4;
                }
            }

            .why-us-description {
                flex-basis: 50%;

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }
            }

            .product-overview {
                .product-card {
                    flex-basis: 33%;

                    .cover {
                        bottom: 40%;
                    }
                }

                &:nth-child(5) {
                    order: 5;
                }
            }

            .block-title {
                &:nth-child(6) {
                    order: 6;
                }
            }

            .technique-overview {
                .technique-introduction {
                    flex-basis: 50%;
                }

                &:nth-child(7) {
                    order: 7;
                }
            }
        }
    }
}

@media screen and (min-width: 912px) {
    .WhyUs {
        .title-bar {
            >img {
                margin-top: 0px;
            }
        }

        .why-us-content {
            .product-overview {
                .product-card {
                    .cover {
                        bottom: 50%;
                    }
                }
            }
        }
    }
}