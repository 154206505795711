.carousel {
    width: 100%;

    .swiper-slide {
        background-color: rgb(238, 238, 238);
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }
}