.languageSelectList {
    position: absolute;
    top: 100%;
    left: 0;
    width: 8.4rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px #999999;

    .select-item {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        
        &:hover {
            background-color: #fbfbfb;
        }
        > .language-name {
            display: inline-block;
            padding: 0 0.3rem;
        }
    }
}