.News {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;

    .title {
        width: 100%;
        height: 180px;
        text-align: center;
        background-color: #509c44;
        color: #fff;

        h1 {
            position: relative;
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
        }
    }

    .news-card {
        display: flex;
        flex-flow: row nowrap;
        flex-basis: 100%;
        box-sizing: border-box;
        padding: 1rem 0.5rem;

        .news-image-container {
            flex-basis: 50%;
            overflow: hidden;

            &::after {
                content: '';
                width: 100%;
                padding-top: calc(1 * 100% / 1);
                display: block;
            }

            .image-holder {
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                height: 100%;
            }
        }

        .news-content-container {
            flex-basis: 50%;
            box-sizing: border-box;
            padding: 0 1rem;

            .news-category {
                display: inline-block;
                margin-right: 0.8rem;
                padding: 0.2rem 0.8rem;
                border-radius: 5px;
                color: #fff;
                background-color: #767676;
            }

            .news-release-date {
                display: inline-block;
            }

            h2 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            b {
                display: block;
                height: 1px;
                background-color: #767676;
                margin: 1rem 0;
            }

            a {
                display: inline-block;
                padding: 0.5rem 1.2rem;
                color: #fff;
                background-color: #509c44;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background-color: #65c556;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .News {
        .news-card {
            flex-basis: 50%;
            padding: 1rem 0;
        }
    }
}