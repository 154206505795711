.Loading {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;

    .spinner {
        width: 64px;
        height: 64px;
        border: 8px solid;
        border-color: #509c44 transparent #509c44 transparent;
        border-radius: 50%;
        animation: animation-spin 1.2s linear infinite;
    }
    
}

@keyframes animation-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}