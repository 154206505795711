.ProductDetail {
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
    color: #666;

    .main-container{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        .menu {
            display: none;
        }

        .content {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 82%;

            .navigator {
                flex-basis: 100%;
                padding: 0 0 0.8rem 0;

                a {
                    color: #666;
                }
            }

            .swiper {
                flex-basis: 50%;
                min-width: 300px;

                img {
                    width: 100%;
                }
            }

            .product-description {
                flex-basis: 100%;
                box-sizing: border-box;
                padding: 1rem;
                font-size: 18px;
            }

            .detail-container {
                display: flex;
                flex-flow: row wrap;
                flex-basis: 100%;

                .detail-content {
                    box-sizing: border-box;
                    padding: 1rem 0;
                    flex-basis: 60%;

                    table {
                        border-spacing: 0;
                        width: 100%;
                        min-width: 320px;

                        tr {
                            text-align: center;

                            th {
                                padding: 10px;
                            }
                        }

                        thead {
                            background-color: #509c44;
                            color: #fff;

                            th {
                                &:first-child {
                                    border-radius: 5px 0 0 0;
                                    border: 1px solid #509c44;
                                }

                                &:last-child {
                                    border-radius: 0 5px 0 0;
                                    border-right: 1px solid #509c44;
                                  }
                            }
                        }

                        tbody {
                            tr {
                                &:nth-child(odd) {
                                    background-color: #d8d8d8
                                }

                                &:last-child {
                                    td {
                                        &:first-child {
                                            border-radius: 0 0 0 5px;
                                        }

                                        &:last-child {
                                            border-radius: 0 0 5px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .contact-us-container {
                    box-sizing: border-box;
                    padding: 1rem 0;
                    flex-basis: 100%;

                    .contact-us-block {
                        height: 100%;
                        box-sizing: border-box;
                        padding: 1rem;
                        border: 1px solid #cdcdcd;
                        border-radius: 10px;

                        h3 {
                            margin-top: 0;
                        }

                        button {
                            border: 0;
                            border-radius: 5px;
                            background-color: #509c44;
                            padding: 0.5rem 1rem;

                            >a {
                                color: #fff;
                                text-decoration: none;
                                font-size: 16px;
                                font-weight: bold;
                            }

                            &:hover {
                                background-color: #5aaf4d;
                            }
                        }
                    }
                }
            }

            .relative-product-container {
                width: 100%;
                box-sizing: border-box;
                margin-top: 1rem;
                padding: 1rem 0;
                border-top: 1px solid #ddd;

                &.lg {
                    display: none;
                }

                &.sm {
                    display: block;
                }

                h2 {
                    margin: 0 0 0.8rem 0;
                    color: #666;
                }

                .product-container {
                    cursor: pointer;
                    
                    p {
                        margin-top: 0;
                        font-size: 16px;
                        text-align: center;
                        color: #333;
                    }                    
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .ProductDetail {
        .main-container {
            .menu {
                display: block;
                width: 18%;
                font-size: 18px;

                ul {
                    list-style: none;

                    li {
                        padding: 0.2rem;
                    }

                    a {
                        text-decoration: none;
                        color: #555;
                    }
                }
            }

            .content {
                .product-description {
                    flex-basis: 50%;
                }

                .detail-container {    
                    .detail-content {
                        padding: 1rem;
                    }
                    .contact-us-container {
                        padding: 1rem;
                        flex-basis: 40%;
                    }
                }

                .relative-product-container {
                    width: 100%;
                    padding: 1rem;
                    
                    &.lg {
                        display: block;
                    }
    
                    &.sm {
                        display: none;
                    }
                }
            }
        }
    }
}